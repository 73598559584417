import {Box, Grid } from "@chakra-ui/react";
import * as React from "react";
import {useEffect, useState} from "react";

export function AssessAHome() {

    useEffect(() => {
    })


    return(
        <Box textAlign="center" fontSize="xl">
            <Grid p={10}>
            </Grid>
        </Box>
    )
}
